import { User, Userv2 } from "models/user";
import Image from "next/image";
import { twMerge } from "tailwind-merge";
import { PropsWithClass, PropsWithClassAndStyle } from "utils/props";
import { XOR } from "utils/xor";

// Old url: https://api.dicebear.com/7.x/initials/${user.first_name} ${user.second_name}.svg?b=%23065F27&r=50&fontSize=35

function initialsUrl(name: string, options?: { backgroundColor?: string; fontSize?: number; radius?: number }) {
  const params = new URLSearchParams({
    seed: name,
    backgroundColor: options?.backgroundColor ?? '065F27',
    fontSize: (options?.fontSize ?? 35).toString(),
    radius: (options?.radius ?? 50).toString()
  })
  return `${process.env.NEXT_PUBLIC_DICEBEAR_BASE_URL}/7.x/initials/svg?${params.toString()}`
}


type UserAvatarProps = {
  width?: number | string
  height?: number | string
  url?: string
  nickname?: string
  user?: Partial<Userv2> & NonNullable<Pick<Userv2, 'first_name' | 'second_name'>>
} & PropsWithClass<{}>



export function UserAvatarv2({ width, height, user, className, style }: PropsWithClassAndStyle<UserAvatarProps>) {
  return <div style={{
    width, height,
    ...(user && { backgroundImage: `url('${initialsUrl(!!user?.first_name && !!user?.second_name ? user!.first_name + ' ' + user!.second_name : user?.nickname ?? '--')}')` }),
    borderRadius: 50,
    ...style,
  }} className={twMerge("bg-grey rounded-full object-cover h-auto bg-cover bg-center", className)} />
}

// export function useAvatar(user: { nickname: string; first_name: undefined; second_name: undefined } | { first_name: string; second_name: string; nickname: undefined } | undefined) {
export function useAvatar(user: Userv2 | undefined) {
  if (!user)
    return undefined
  // const { nickname, first_name, second_name } = user
  // const initials = first_name && second_name ? `${first_name} ${second_name}` : nickname ?? 'RH'
  //
  return initialsUrl(!!user?.first_name && !!user?.second_name ? user!.first_name + ' ' + user!.second_name : user?.nickname ?? '--')
}

export function UserAvatar({ width, height, url, nickname, user, className }: UserAvatarProps) {
  return <img
    // src="https://dicebear.com/api/7.x/initials/VD%20.svg?backgroundColors[]=green"
    // src="https://dicebear.com/api/7.x/initials/vla%20d.svg?b=%23b6dd4b&r=50&scale=101&fontSize=47"
    // src={url ?? "https://dicebear.com/api/7.x/initials/vd.svg?b=%23a1be78&r=50&fontSize=35"}
    // src={url ?? `https://api.dicebear.com/7.x/initials/${user ? `${user.first_name} ${user.second_name}` : nickname ?? 'vd'}.svg?b=%23065F27&r=50&fontSize=35`}
    src={url ?? initialsUrl(!!user?.first_name && !!user?.second_name ? user!.first_name + ' ' + user!.second_name : nickname ?? user?.nickname ?? '--')}
    width={width} height={height}
    className={className}
  />
}
